import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { useTheme } from '@mui/material/styles';

const NetworkGraph = ({ data, searchTerm, nodeReveal, sectorReveal, nodeCount, depthCount }) => {
  const ref = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (data === null || !Array.isArray(data.links) || !Array.isArray(data.nodes)) {
      return;
    }
    
    let nodes = [...data.nodes];
    
    nodes.sort((a, b) => a.retrievalOrder - b.retrievalOrder);  // Sort by retrievalOrder
    nodes = nodes.slice(0, nodeCount);  // Take only the first nodeCount nodes

    // Filter by depth too
    nodes = nodes.filter(node => node.depthNumber <= depthCount);
  
    // Create a set of ids for these nodes
    const nodeIds = new Set(nodes.map(node => node.id));
  
    // Filter the links to only include those between the filtered nodes
    const links = data.links.filter(link => nodeIds.has(link.source.id || link.source) && nodeIds.has(link.target.id || link.target));

    // Clear the SVG
    d3.select(ref.current).selectAll("*").remove();

    const baselineFontSize = 14;  
    const svg = d3.select(ref.current);
    const container = svg.append("g");
    const width = svg.node().clientWidth;
    const height = svg.node().clientHeight;

    // const links = data.links.map(d => Object.create(d));
    // const nodes = data.nodes.map(d => Object.create(d));

    const drag = simulation => {
      const dragstarted = event => {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      };

      const dragged = event => {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      };

      const dragended = event => {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      };

      return d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    };

    const zoom = d3.zoom()
      .on("zoom", event => {
        container.attr("transform", event.transform);

        const zoomLevel = event.transform.k;
        text.style("display", zoomLevel > 0.8 ? "block" : "none");
      
        const newFontSize = Math.max(12, baselineFontSize / zoomLevel);
        text.style("font-size", `${newFontSize}px`);
      });

    svg.call(zoom);

    const simulation = d3.forceSimulation(nodes)
    .force("link", d3.forceLink(links).id(d => d.id).distance(70))
    .force("charge", d3.forceManyBody().strength((d) => -Math.pow(d.label.length, 1.5)))
    .force("center", d3.forceCenter(width / 2, height / 2))
    .force("collide", d3.forceCollide((d) => 40));
  
  

    const link = container.append("g")
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke",  'white' )
      .attr("stroke-opacity", 0.3)
      .attr("stroke-width", 2);  ;

    const node = container.append("g")
      .selectAll("g")
      .data(nodes)
      .join("g")
      .call(drag(simulation));

    node.append("circle")
      .attr("r", d => {
        if (d.label.toLowerCase() === searchTerm.toLowerCase()) {
          return 30;
        }  else if (d.label.toLowerCase() === nodeReveal.toLowerCase()) {
          return 15;
        }
        else {
          return 5;
        }
      })
      .attr("fill", d => {
        if (d.label.toLowerCase() === searchTerm.toLowerCase()) {
          return 'white';
        } else if (d.label.toLowerCase() === nodeReveal.toLowerCase()) {
          return 'white';
        } else {
          if (sectorReveal) {
            if (d.industry === sectorReveal) {
              return 'blue'
            }
            else {
              return d.type === 'company' ? theme.palette.tertiary.main : theme.palette.secondary.main;
            }
          }
          else {
            return d.type === 'company' ? theme.palette.tertiary.main : theme.palette.secondary.main;
          }
         
        }
    
      })
      .attr("stroke", d => {
        if (d.label.toLowerCase() === searchTerm.toLowerCase()) {
          return '';
        } else if (d.label.toLowerCase() === nodeReveal.toLowerCase()) {
          return theme.palette.tertiary.main;
        } else {
          return d.type === 'company' ? theme.palette.tertiary.main : theme.palette.secondary.main;
        }
      });

    const text = node.append("text")
      .text(d => d.label.length > 20 ? d.label.substr(0, 17) + '...' : d.label)
      .attr("x", 12)
      .attr("y", 4)
      .style("display", "none")
      .attr("fill", theme.palette.secondary.main)
      .each(function(d) { // For each text element, append a title element with the full label
        d3.select(this).append("title").text(d.label);
      });
    


    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node.attr("transform", d => `translate(${d.x}, ${d.y})`);
    });
  }, [data, nodeCount, nodeReveal, sectorReveal, depthCount, searchTerm, theme.palette.primary.main, theme.palette.secondary.main, theme.palette.tertiary.main, theme.palette.accent.main]);

  return <svg ref={ref} style={{ width: '100%', height: '100vh' }} />;
};

export default NetworkGraph;
