import React from 'react';
import NavBar from './Header';
import Banner from './Banner'


const MainContent = () => {
  return (
    <div>
    <NavBar/>
    <Banner/>
    </div>

  )
}

export default MainContent;