import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, AppBar, Toolbar, IconButton, Box, CardMedia, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SearchBar from './StyledSearchBar';
import NetworkGraph from './NetworkGraph';
import StyledContainer from './StyledContainer';
import LogoBlack from '../media/images/logo_boardviz_text.png';  // Replace this path with the actual path to your image
import { Link, useLocation } from 'react-router-dom';
import InfluencialNodeBarChart from './graphs/InfluencialNodesBar';
import PieChart from './graphs//PieChart';  // Adjust the import based on your folder structure
import RangeSlider from './filters/FilterSlider'
import { useTheme } from '@mui/material/styles';


const AlternateDashboard = () => {
  const theme = useTheme(); 

  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [nodeReveal, setNodeReveal] = useState('');
  const [sectorReveal, setSectorReveal] = useState('');

  const [uniqueLabels, setUniqueLabels] = useState([]);
  const [uniqueSectors, setUniqueSectors] = useState([]);


  const urlAutcompleteCompanies = process.env.REACT_APP_API_URL;

  const [nodeCount, setNodeCount] = React.useState(50);
  const [depthCount, setdepthCount] = React.useState(20);

  const toggleLeftDrawer = () => {
    setLeftDrawerOpen(!leftDrawerOpen);
  };
  const toggleRightDrawer = () => {
    setRightDrawerOpen(!rightDrawerOpen);
  };


  const [data, setData] = useState(null);
  
  const fetchFunction = useCallback(async (searchQuery) => {
    try {
      const response = await fetch(`${apiUrl}/api/network-data/?search_term=${searchQuery}&max_nodes=500`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  }, [apiUrl]);


  const [lastFetchedQuery, setLastFetchedQuery] = useState(null);

  
  useEffect(() => {
    if (data && data.nodes) {
      // Now you're using state setters, no need to declare them with let
      const newUniqueLabels = [...new Set(data.nodes.map(node => ({
        id: node.id,
        name: node.label
      })))];

      const industries = data.nodes.map(node => node.industry).filter(Boolean);

      // Now create a Set for the unique industry names
      const uniqueIndustriesSet = new Set(industries);
  
      // Map the unique industry names back to objects with id and name
      const newUniqueSectors = Array.from(uniqueIndustriesSet).map(industry => ({
        id: 'sector',
        name: industry 
      }));
      setUniqueLabels(newUniqueLabels);
      setUniqueSectors(newUniqueSectors);
    }
  }, [data]);
  

useEffect(() => {
  let query = searchTerm || new URLSearchParams(location.search).get('search');

  // If searchTerm isn't set, try to initialize it from the URL
  if (!searchTerm) {
    const initialSearchTerm = new URLSearchParams(location.search).get('search');
    if (initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
      query = initialSearchTerm;
    }
  }

  if (query && query !== lastFetchedQuery) {
    fetchFunction(query);
    setLastFetchedQuery(query);
  }
}, [location.search, searchTerm, lastFetchedQuery, fetchFunction]);


  const onLaunchGraph = (query) => {
    setSearchTerm(query); // Set the search term state

    if (data) {
      setData(null);
    }
  };

  const handleNodeRevealSelect = (value) => {
    setNodeReveal(value);
  };

  const handleSectorRevealSelect = (value) => {
    setSectorReveal(value);
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ zIndex: 1201,  px: '2em'}}> 
        <Toolbar>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box display="flex" alignItems="flex-start" >
                <CardMedia
                        component="img"
                        image={LogoBlack}
                        height="auto"
                        alt="Banner Image"
                        sx={{ height: '50%', width: '50%' }}
                    />
                </Box>
            </Link>
          <Box sx={{ flexGrow: 1, }} />
          
          <IconButton edge="end" color="inherit" onClick={toggleRightDrawer}>
            <AnalyticsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Left Vertical Navbar */}
      <Box
        sx={{
          position: "fixed",
          top: 64,  // 64px tall AppBar
          left: 0,
          height: '100%',
          width: '4rem',
          bgcolor: "#191A23",
          zIndex: 1200,  
        }}
      >
        <IconButton color="secondary" onClick={toggleLeftDrawer}>
          <MenuIcon />
        </IconButton>
      </Box>

      {/* Left Drawer */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={leftDrawerOpen}
        PaperProps={{ sx: { bgcolor: "#191A23", top: '64px', position: 'absolute' } }}
      >
        <Box sx={{ width: 350,  paddingInline : '2rem',
          marginTop: 0,
          transition: "margin 0.5s",
          flexDirection: "row",
          alignItems: "top",
          height: '100%' }}>
          <IconButton onClick={toggleLeftDrawer} color="secondary">
            <CloseIcon />
          </IconButton>
          {/* Your left panel content here */}
          
          <StyledContainer textColor='white' variant='h3'> 
            <StyledContainer title="Show in graph" textColor='white' variant='h4'>
            <Box paddingBottom={'3em'} width={'80%'}>  
              <Typography paddingTop={'1em'} paddingBottom={'1em'} variant='h5' color="white">Reveal by name is:</Typography>
              {/* <DropDown selectText={'Reveal nodes in the graph'} options={optionsShow} selected={selectedOption} onChange={handleDropdownChange} />   */}
              <SearchBar inputMessage="Reveal node in the graph" inputFontSize={10} buttonMessage="Rechercher" onSearch={setNodeReveal} colorOutline={theme.palette.secondary.main} autocompleteUrl={urlAutcompleteCompanies} showButton={false} onAutocompleteSelect={handleNodeRevealSelect} autocompleteData={uniqueLabels}/>
          
            </Box>
            <Box paddingBottom={'3em'} width={'80%'}>  
              <Typography paddingTop={'1em'} paddingBottom={'1em'} variant='h5' color="white">Reveal by sector is:</Typography>
              {/* <DropDown selectText={'Reveal nodes in the graph'} options={optionsShow} selected={selectedOption} onChange={handleDropdownChange} />             */}
              <SearchBar inputMessage="Reveal sector in the graph" inputFontSize={10} buttonMessage="Rechercher" onSearch={setSectorReveal} colorOutline={theme.palette.secondary.main} showButton={false} onAutocompleteSelect={handleSectorRevealSelect} autocompleteData={uniqueSectors}/>

            </Box>
            </StyledContainer>
            
            <StyledContainer title="Display settings" textColor='white' variant='h4'>
              <Box paddingBottom={'3em'} width={'80%'}>              
                <Typography paddingTop={'1em'} paddingBottom={'1em'} variant='h5' color="white">Maximum number of nodes:</Typography>
                <Box><RangeSlider nodeCount={nodeCount} setNodeCount={setNodeCount} min={0} max={5000}/></Box>
              </Box>
              <Box paddingBottom={'3em'} width={'80%'}>              
                <Typography paddingTop={'1em'} paddingBottom={'1em'} variant='h5' color="white">Maximum depth:</Typography>
                <Box><RangeSlider nodeCount={depthCount} setNodeCount={setdepthCount} min={0} max={20}/></Box>
              </Box>
            </StyledContainer>
            </StyledContainer>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        sx={{
          marginLeft: leftDrawerOpen ? '25rem' : '5em',
          marginRight: rightDrawerOpen ? '25rem' : '3em',
          marginTop: 15, 
          transition: "margin 0.5s",
          flexDirection: "row",
          alignItems: "top",
          height: '100%'
        }}
      >
    <SearchBar mainSearch={'true'} inputMessage="Search for items..." buttonMessage="Rechercher" onSearch={onLaunchGraph} colorOutline={theme.palette.tertiary.main} autocompleteUrl={urlAutcompleteCompanies} showButton={true}/>
  </Box> 
    {data !== null && <NetworkGraph data={data} searchTerm={searchTerm} nodeReveal={nodeReveal} sectorReveal={sectorReveal} nodeCount={nodeCount} depthCount={depthCount}/>}

      {/* Right Drawer */}
      <Drawer
        variant="persistent"
        anchor="right"
        open={rightDrawerOpen}
        PaperProps={{ sx: { bgcolor: "#191A23", } }}
      >
        <Box sx={{ width: 350, paddingInline : '2rem', paddingTop: '4em'}}>
          <IconButton onClick={toggleRightDrawer} color="inherit"> 
            <CloseIcon />
          </IconButton>
          <StyledContainer textColor='white' variant='h3'> 
            <StyledContainer title="Most influencial nodes" textColor='white' variant='h4'> 
            <InfluencialNodeBarChart topInfluential={data?.topInfluential} />

            </StyledContainer>
            <StyledContainer title="Share" textColor='white' variant='h4'>
            
            {data !== null && <PieChart data={data.industryStats} title="Industry Statistics:" />}
            {data !== null &&<PieChart data={data.locationStats} title="Location Statistics:" />}
            </StyledContainer>
          </StyledContainer>

        </Box>
      </Drawer>
    </div>
  );
};

export default AlternateDashboard;
