import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const PieChart = ({ data, title }) => {
    
  const ref = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (!data) {
      return;
    }

    d3.select(ref.current).selectAll("*").remove();

    const total = Object.entries(data).reduce((acc, [, value]) => acc + value, 0);

    const width = 120;
    const height = 110;
    const radius = Math.min(width, height) / 2;

    const svg = d3.select(ref.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const color = d3.scaleOrdinal()
      .range(["#FF5733", "#4AD763", "#AC29E6", "#FFC300", "#900C3F", "#DAF7A6", "#C70039", "#581845", "#FFD033", "#17A589"]);

    const pie = d3.pie()
      .value(d => d.value);

    const dataReady = pie(Object.entries(data).map(([key, value]) => ({ key, value })));

    const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius);

    svg
      .selectAll('whatever')
      .data(dataReady)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', d => color(d.data.key))
      .style('stroke-width', '2px')
      .on('mouseover', function (event, d) {
        if (d.data.value / total <= 0.13) {
          svg
            .append('text')
            .attr('class', 'hover-label')
            .attr('transform', `translate(${arc.centroid(d)})`)
            .style('text-anchor', 'middle')
            .style('font-size', 10)
            .style('fill', theme.palette.primary.main)
            .text(d.data.key);
        }
      })
      .on('mouseout', function (event, d) {
        svg.selectAll('.hover-label').remove();
      });

    svg
      .selectAll('text')
      .data(dataReady)
      .enter()
      .append('text')
      .each(function(d) {
        if(d.data.value / total > 0.13) {
          const textEl = d3.select(this);
          const [x, y] = arc.centroid(d);
          const words = d.data.key.split(' ');
          let line = [];
          let lineNumber = 0;
          const maxCharsPerLine = 5;
  
          words.forEach(function(word) {
            line.push(word);
            const joinedLine = line.join(' ');
  
            if (joinedLine.length > maxCharsPerLine) {
              textEl
                .append('tspan')
                .text(joinedLine)
                .attr('x', x)
                .attr('y', y + (lineNumber * 10))
                .attr('text-anchor', 'middle')
                .style('font-size', 10)
                .style('fill', theme.palette.primary.main);
              lineNumber++;
              line = [];
            }
          });
  
          if (line.length > 0) {
            textEl
              .append('tspan')
              .text(line.join(' '))
              .attr('x', x)
              .attr('y', y + (lineNumber * 10))
              .attr('text-anchor', 'middle')
              .style('font-size', 10)
              .style('fill', theme.palette.primary.main);
          }
        }
       
      });

  }, [data, theme]);

  return (
    <div>
      <Typography variant='h5' paddingBottom="2em" display="center" color={'white'}>{title}</Typography>
      <svg ref={ref}></svg>
    </div>
  );
};

export default PieChart;
