import React from 'react';
import { Typography, Box } from '@mui/material';


const SectionTitle = ({ title, bgColor, textColor, variant }) => {
    console.log(textColor)
    return (
    <Box bgcolor={bgColor} pt={3} borderRadius={2} display="flex" alignItems="flex-start">
        <Typography fontWeight={'bold'} variant={variant} display="inline"  color={textColor}>
            {title}
        </Typography>
    </Box>
);
    };

export default SectionTitle