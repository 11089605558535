import React from "react";
import * as d3 from "d3";
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef } from 'react';

    
const InfluencialNodeBarChart = ({topInfluential}) => {
    const theme = useTheme();
    const ref = useRef();
    
    
    useEffect(() => {
        if (!topInfluential) {
          return;
        }
        d3.select(ref.current).selectAll("*").remove();

        // Margin and dimensions for the SVG container
        const margin = { top: 10, right: 0, bottom: 10, left: 70 };
        const width = 200 - margin.left - margin.right;
        const height = 90 - margin.top - margin.bottom;
    
       // Create SVG element
    const svg = d3.select(ref.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Set up scales
    const y = d3.scaleBand()
      .domain(topInfluential.map(d => d.label))
      .range([0, height])
      .padding(0.3);

    const x = d3.scaleLinear()
      .domain([0, d3.max(topInfluential, d => d.influenceScore)])
      .range([0, width]);

    const yAxis = d3.axisLeft(y);
    svg.append('g')
      .call(yAxis)
      .select(".domain").remove();
 
      svg.append('g')
      .call(d3.axisLeft(y))
      .selectAll('text')
      .each(function(d) {
        const self = d3.select(this);
        const words = d.split(' ');
        const maxCharsPerLine = 9;
        let line = [];
        let lineNumber = 0;

        self.text('');
        words.forEach(function(word) {
          line.push(word);
          const joinedLine = line.join(' ');

          if (joinedLine.length > maxCharsPerLine) {
            self
              .append('tspan')
              .attr('dominant-baseline', 'top') // vertically align text
              .text(joinedLine)
              .attr('x', 0)
              .attr('dy', `${lineNumber * 1}em`)
              .attr('fill', theme.palette.tertiary.main);
            lineNumber++;
            line = [];
          }
        });

        if (line.length > 0) {
          self
            .append('tspan')
            .text(line.join(' '))
            .attr('x', 0)
            .attr('dy', `${lineNumber * 1.2}em`)
            .attr('fill', theme.palette.tertiary.main);
        }
      });

    // Add bars
    svg.selectAll('.bar')
      .data(topInfluential)
      .enter().append('rect')
      .attr('x', 10)  // Adding 5 pixels of padding
      .attr('class', 'bar')
      .attr('y', d => y(d.label))
      .attr('width', d => x(d.influenceScore))
      .attr('height', y.bandwidth())
      .attr('fill', theme.palette.tertiary.main);

    //   Shows the score of influence at the end of the bar
    svg.selectAll('.bar-label')
      .data(topInfluential)
      .enter().append('text')
      .attr('class', 'bar-label')
      .attr('font-size', 8)
      .attr('y', d => y(d.label) + y.bandwidth() / 2)  // Position at the middle of the bar
      .attr('x', d => x(d.influenceScore) + 18) // Position a bit inside the bar from the right end
      .attr('text-anchor', 'middle')  // align text right
      .attr('dominant-baseline', 'middle') // vertically align text
      .attr('fill', theme.palette.secondary.main) // color of text
      .text(d => d.influenceScore);

    
      svg.selectAll("path,line").remove();

    }, [topInfluential, theme.palette.secondary.main, theme.palette.tertiary.main]);
    
    return (
        <svg ref={ref}></svg>
    );
    };
    
export default InfluencialNodeBarChart;