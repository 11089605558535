import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField, InputAdornment, Button, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';


const SearchBar = ({ inputMessage, mainSearch, buttonMessage, onSearch, colorOutline, autocompleteUrl, showButton, inputFontSize, onAutocompleteSelect, autocompleteData}) => {
  const apiUrl = autocompleteUrl;
  const theme = useTheme(); 
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [selectedItem, setSelectedItem] = useState(''); 
  // debugger;
  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = useCallback(async (query) => {
    const response = await fetch(`${apiUrl}/api/companies/?query=${query}`);
    const data = await response.json();
    setSuggestions(data);
  }, [apiUrl]);
  
  const debouncedFetchSuggestions = useMemo(() => debounce(fetchSuggestions, 300), [fetchSuggestions]);

  
  useEffect(() => {
    if (autocompleteData) {
      setSuggestions(autocompleteData);
    }    
    else if (search) {
      debouncedFetchSuggestions(search);
    } else {
      setSuggestions([]);
    }
  }, [search, debouncedFetchSuggestions, autocompleteData, onAutocompleteSelect]);

  

  const handleInputChange = (event, value, reason) => {
    if (reason === 'input') {
      setSearch(value);
    }
  };

  const handleSearch = () => {
    const query = selectedItem || search; // Use selectedItem if available, fallback to search otherwise
    if (mainSearch && query) {
      navigate(`/dashboard?search=${query}`);
    }
    if (onSearch && mainSearch) {
      onSearch(query);
      setSearch(query);
      navigate(`/dashboard?search=${query}`);
    }
    else if (onSearch) {
      onSearch(query);
      setSearch(query);
    }
    if (onAutocompleteSelect) {
      onAutocompleteSelect(selectedItem);
    }
  };

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));


  return (
    <Box width="100%" mx="auto" >
      <Autocomplete
        freeSolo
        options={suggestions.map((option) => option.name)}
        onChange={(event, newValue) => {
          setSelectedItem(newValue); // Store the selected item when an item is selected from the dropdown
          if (onAutocompleteSelect) {
            onAutocompleteSelect(newValue);
          } 
        }}
       
        renderInput={(params) => (
          <TextField  
            {...params} 
            label={inputMessage} 
            variant="outlined" 
            fullWidth
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            sx={{ fontSize:inputFontSize, border: `2px solid ${colorOutline}`, '& .MuiInputBase-input': {
              color: 'secondary.main',
            } }}
            InputLabelProps={{
              style: { color: theme.palette.secondary.main, fontSize: '8' }, 
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                {params.InputProps.endAdornment}
                  {showButton && (
                    <InputAdornment position="end">
                      <Button variant="contained" color="tertiary" onClick={handleSearch}>
                      {isMobile ? <SearchIcon /> : buttonMessage}
                      </Button>
                    </InputAdornment>
                  )}
                   
                </>
              ),
            }}
          />
        )}
        onInputChange={handleInputChange}
      />
    </Box>
  );
};

export default SearchBar;
