import { Box } from "@mui/material";
import SectionTitle from "./SectionTitle";
import { useTheme } from '@mui/material/styles';


const StyledContainer = ({ title, bgColor, children, textColor, boxBackgroundColor, height, variant}) => {
  const theme = useTheme(); 

  return (
    <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start"
          sx={{
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '1em',
            boxShadow: 1,
            pl: 2,
            pr: 2,
            pb: 2,
            mt: '1rem',
            textAlign: 'left',
          }}
          >
      <SectionTitle title={title} bgColor={bgColor} textColor={textColor} variant={variant}/>
      <Box sx={{width: '100%'}}>
      {children}
      </Box>
      
    </Box>
  );
};

export default StyledContainer;
