import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import SearchBar from './StyledSearchBar'
import { useTheme } from '@mui/material/styles';
const urlAutcompleteCompanies = process.env.REACT_APP_API_URL;


function Banner() {
    const bannerStyle = {
      color: '#76CDE9',
      textAlign: 'center',
      fontFamily: 'Roboto Condensed',
      fontWeight: 800,
      fontVariant: 'all-small-caps',
    };
    
    const pinkWordStyle = {
      color: '#F750BF',
    };

    const theme = useTheme();

    
    
    return (

    <Box pt={2} display="flex" flexDirection="row" justifyContent="center"height={'80vh'} width={'`60wh'}>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Box pb={2}>
            <Typography variant="h1" align="center" style={bannerStyle}>
              Découvrez les connections <span style={pinkWordStyle}>invisibles</span> des entreprises
            </Typography>
            <Container>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: { sm: '100%' }}}>
              <SearchBar mainSearch={'true'} inputMessage="Recherchez une entreprise" buttonMessage="Rechercher" colorOutline={theme.palette.tertiary.main} autocompleteUrl={urlAutcompleteCompanies} showButton={true}/>
              </Box>
            </Container>
          </Box>
        </Box>
    </Box>
    );
  }
  
  export default Banner;