import React, { useEffect } from 'react'
import { GA4React } from 'ga-4-react';
import { Route, Routes } from 'react-router-dom';
import AlternateDashboard from './components/AlternateDashboard'

import './App.css';
import MainContent from './components/MainContent';

import { GlobalStyles } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import AboutUs from './components/AboutUs';

const ga4 = new GA4React("G-6JGY49J0W2");

const theme = createTheme({
  palette: {
    primary: {
      main: '#012345',
    },
    secondary: {
      main: '#82C6D3',
    },
    tertiary: {
      main: '#F750BF',
    },
    accent: {
      main: '#F750BF)'
    }
  },
  typography: {
    fontFamily: "'Lato', sans-serif", // Setting the default font to Lato
    fontSize: '1rem',
    h1: {
      fontFamily: "'Roboto Condensed', sans-serif", // Setting h1 to Roboto Condensed
      fontSize: '3rem',
    },
    h2: {
      fontFamily: "'Roboto Condensed', sans-serif", // Setting h1 to Roboto Condensed
      fontSize: '2.5rem',
    },
    h3: {
      fontFamily: "'Roboto Condensed', sans-serif", // Setting h1 to Roboto Condensed
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    h4: {
      fontFamily: "'Roboto Condensed', sans-serif", // Setting h1 to Roboto Condensed
      fontSize: '1.1rem',
      fontWeight: '300'
    },
  },
});


function App() {
  useEffect(() => {
    ga4.initialize().then((ga4) => {
      ga4.pageview(window.location.pathname + window.location.search);
    }, (err) => {
      console.error("Analytics failure", err);
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{
        body: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main
        },
      }} />
    <div className="App">
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/dashboard" element={<AlternateDashboard />} />
        <Route path="/about-us" element={<AboutUs />} />

      </Routes>
    </div>
    </ThemeProvider>
  );
}

export default App;
