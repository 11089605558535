import * as React from 'react';
import { Slider, Box } from '@mui/material';

export default function RangeSlider({nodeCount, setNodeCount, min, max}) {

  const handleChange = (event, newValue) => {
    setNodeCount(newValue);
  };

  return (
    <Box paddingLeft={'0.5em'}>
      <Slider
        getAriaLabel={() => 'Range slider'}
        value={nodeCount}
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={min} 
        max={max} 
        sx={{
            color: 'secondary.main', 
            '& .MuiSlider-thumb': { 
                backgroundColor: 'secondary.main', 
            },
            
        }}
      />
    </Box>
  );
}
